// @flow
import API from '../axios';
import { FETCH_SOURCES_VIEWS } from "../constants/actionTypes";

type PageAnalytics = {
    pageViews?: ?Object,
    pageViewsPrevious?: ?Object,
    pageVisitors?: ?Object,
    pageVisitorsPrevious?: ?Object
}

type Action = {
    +type: string,
    payload: PageAnalytics,
    error?: ?string
}

type Params = {
    start: number,
    end: number
}

/**
 * @param action
 * @returns {*}
 */
const dispatchSourcesViews = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_SOURCES_VIEWS.REQUEST };
        case 'success':
            return {
                type: FETCH_SOURCES_VIEWS.SUCCESS,
                payload: {
                    pageViews: action.payload.pageViews,
                    pageViewsPrevious: action.payload.pageViewsPrevious,
                    pageVisitors: action.payload.pageVisitors,
                    pageVisitorsPrevious: action.payload.pageVisitorsPrevious
                }
            };
        case 'failure':
            return {
                type: FETCH_SOURCES_VIEWS.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 *
 * @param params
 * @returns {Function}
 */
export function fetchSourcesViews(params: Params) {
    const url: string = "/sources/views?start=" + params.start + '&end=' + params.end;
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchSourcesViews({type: 'request', payload: {}}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchSourcesViews( {type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchSourcesViews({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null,
                payload: {}
            })))
    };
}

/**
 *
 * @returns {Function}
 */
export function getSourcesViews() {
    return fetchSourcesViews({start: -14, end: 0});
}
