import axios from 'axios';
import {getAuthEndpoint} from './actions/AuthActions';
import {getApiEndpoint} from './_helpers/endpoints';
import store from "./store";
import {USER_IS_AUTHENTICATED, USER_IS_UNAUTHENTICATED} from "./constants/actionTypes";


let httpClient = axios.create({
    baseURL: getApiEndpoint()
});

httpClient.interceptors.response.use(response => response, error => {
    const {response: {status}} = error;
    if (status === 401) {
        store.dispatch({type: USER_IS_UNAUTHENTICATED});
    }

    return Promise.reject(error);
});

httpClient.interceptors.request.use(async config => {
    const user = store.getState().authReducer.user;

    if (user) {
        const accessTokenCreatedAt = user.created_at;
        const accessTokenTtl = user.expires_in;
        const refreshToken = user.refresh_token;

        if (!config.isTokenRefreshingRequest) {
            if (null === localStorage.getItem('isTokenRefreshingInProgress') && accessTokenTtl * 1000 <= Date.now() - accessTokenCreatedAt) {
                localStorage.setItem('isTokenRefreshingInProgress', 'yes');
                httpClient.get(getAuthEndpoint('refresh_token') + '&refresh_token=' + refreshToken, {isTokenRefreshingRequest: true})
                    .then((response) => {
                        store.dispatch({type: USER_IS_AUTHENTICATED, payload: {...response.data, created_at: Date.now()}});
                        localStorage.removeItem('isTokenRefreshingInProgress');
                    })
                    .catch(() => {
                        localStorage.removeItem('isTokenRefreshingInProgress');
                    });
            }

            if (null !== localStorage.getItem('isTokenRefreshingInProgress')) {
                while(null !== localStorage.getItem('isTokenRefreshingInProgress')) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }
            }
            config.headers.Authorization = 'Bearer ' + user.access_token;
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default httpClient;
