import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';

export default (WrappedComponent) => {
    class Authentication extends Component {
        isLoggedIn = () => {
            return this.props.user;
        };

        render() {
            if (this.isLoggedIn()) {
                return <WrappedComponent {...this.props} />
            } else {
                return <Redirect to="/login" />
            }
        }
    }

    const mapStateToProps = (state) => {
        return {
            user: state.authReducer.user
        }
    };

    return connect(mapStateToProps)(Authentication);
};
