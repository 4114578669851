// @flow
import React from 'react';
import {Spinner} from "../UI/Spinner";
import FormsLineChart from "../LineChart/FormsLineChart/FormsLineChart";
import Ibox from "../Ibox/Ibox";
import PropTypes from 'prop-types';

type Props = {
    forms: {
        submitted: {
            loading: boolean,
            count: Object,
            error: string
        },
        viewed: {
            loading: boolean,
            count: Object
        },
        closed: {
            loading: boolean,
            count: Object
        }
    }
}

const FormsLineChartElement = (props: Props) => {
    let data: ?Object = null;

    if (
        props.forms.submitted.loading
        && props.forms.viewed.loading
        &&  props.forms.closed.loading
    ) {
        data = <Spinner style={{width: '30px', height: '30px'}} />;
    } else if (
        props.forms.submitted.count
        && props.forms.viewed.count
        &&  props.forms.closed.count
    ) {
        data = <FormsLineChart
            submittedForms={props.forms.submitted.count}
            viewedForms={props.forms.viewed.count}
            closedForms={props.forms.closed.count}
        />;
    } else {
        data = props.forms.submitted.error;
    }

    return (
        <Ibox style={{padding: '15px 20px 20px 20px', minHeight: '287px'}}>
            {data}
        </Ibox>
    )
};

FormsLineChartElement.propTypes = {
    forms: PropTypes.shape({
        submitted: PropTypes.shape({
            loading: PropTypes.bool,
            count: PropTypes.object,
            error: PropTypes.string
        }),
        viewed:  PropTypes.shape({
            loading: PropTypes.bool,
            count: PropTypes.object,
        }),
        closed: PropTypes.shape({
            loading: PropTypes.bool,
            count: PropTypes.object,
        })
    })
};

export default FormsLineChartElement;
