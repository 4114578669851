// @flow
import React, {Component} from 'react';
import './Table.scss';
import {Spinner} from '../Spinner';

type Props = {
    isLoading: boolean,
    style?: ?Object,
    thead: any,
    tableData: any
}

export default class Table extends Component<Props> {
    render() {
        return (
            <div className="ibox-content table-wrapper">
                {
                    this.props.isLoading
                        ? <Spinner style={{width: '30px', height: '30px'}} />
                        :   <table className="table users-table" style={this.props.style}>
                                <thead>
                                    {this.props.thead}
                                </thead>
                                <tbody>
                                    {this.props.tableData}
                                </tbody>
                            </table>
                }
            </div>
        );
    }
}
