import React from 'react';
import './LeftMenuIcon.scss';
import UniBaseLogo from  '../../../assets/img/UniBase.svg';

export default () => {
    return (
        <li className="nav-header">
            <div className="profile-element">
                <img alt="UniBase Logo" src={UniBaseLogo} />
                <div>
                    <span>Панель управления</span>
                </div>
            </div>
        </li>
    );
};
