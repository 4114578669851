// @flow
import { FETCH_TOTAL_MONEY_AMOUNT } from '../constants/actionTypes';

type State = {
    totalAmount: ?string,
    loading: boolean,
    error: ?string
}

type Action = {
    type: string,
    payload?: string,
    error?: string
}

export const initialState = {
    totalAmount: null,
    loading: false,
    error: null
};

const billingRecordsReducer = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case FETCH_TOTAL_MONEY_AMOUNT.REQUEST:
            return {
                ...state,
                loading: true,
                totalAmount: null,
                error: null
            };
        case FETCH_TOTAL_MONEY_AMOUNT.SUCCESS:
            return {
                ...state,
                loading: false,
                totalAmount: action.payload,
                error: null
            };
        case FETCH_TOTAL_MONEY_AMOUNT.FAILURE:
            return {
                ...state,
                loading: false,
                totalAmount: null,
                error: action.error
            };
        default:
            return state;
    }
};

export default billingRecordsReducer;
