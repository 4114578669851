// @flow
import React from 'react';
import {Spinner} from "../UI/Spinner";
import EmailsLineChart from "../LineChart/EmailsLineChart/EmailsLineChart";
import Ibox from "../Ibox/Ibox";
import PropTypes from 'prop-types';

type Props ={
    emails : {
        loading: boolean,
        count: Object,
        error: string
    }
}

const EmailsLineChartElement = (props: Props) => {
    let data = null;

    if (props.emails.loading) {
        data = <Spinner style={{width: '30px', height: '30px'}} />;
    } else if (props.emails.count) {
        data = <EmailsLineChart
            emailsAnalytics={props.emails.count}
        />;
    } else {
        data = props.emails.error;
    }

    return (
        <Ibox style={{padding: '15px 20px 20px 20px', minHeight: '287px'}}>
            {data}
        </Ibox>
    )
};

EmailsLineChartElement.propTypes = {
    emails: PropTypes.shape({
        loading: PropTypes.bool,
        count: PropTypes.object,
        error: PropTypes.string
    })
};

export default EmailsLineChartElement;
