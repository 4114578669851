// @flow
import React from 'react'

type Props = {
    data: string,
    symbol?: string
}

export const FormattedNumber = (props: Props) => {
    const formatter = (num) => {
        let parts: Array<string> = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts[0];
    };

    let data: Array<string> = formatter(props.data).split(' ');
    let specialSymbol: ?string = props.symbol ? props.symbol : null;

    return (
            <span>
                {data.map((key, index) => {
                    if (index === 0) {
                        return <span key={index}>{key}</span>;
                    } else {
                        return <span key={index} style={{paddingLeft: '5px'}}>{key}</span>;
                    }
                })}
                <span style={{paddingLeft: '5px'}}>{specialSymbol}</span>
            </span>
    )
};
