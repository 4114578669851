// @flow
import { FETCH_EMAILS_STATUSES } from '../constants/actionTypes';

type State = {
    count: ?Object,
    loading: boolean,
    error: ?string
}

type Action = {
    type: string,
    payload?: Object,
    error?: string
}

export const initialState = {
    count: null,
    loading: false,
    error: null
};

const emailsReducer = (state: State = initialState, action: Action) => {
    switch (action.type) {
        case FETCH_EMAILS_STATUSES.REQUEST:
            return {
                ...state,
                loading: true,
                count: null,
                error: null
            };
        case FETCH_EMAILS_STATUSES.SUCCESS:
            return {
                ...state,
                loading: false,
                count: action.payload,
                error: null
            };
        case FETCH_EMAILS_STATUSES.FAILURE:
            return {
                ...state,
                loading: false,
                count: null,
                error: action.error
            };

        default:
            return state;
    }
};

export default emailsReducer;
