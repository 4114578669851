// @flow
import React, {Component} from 'react';
import './AffiliatesPage.scss';
import Table from "../../../components/UI/Table/Table";
import Pagination from "../../../components/UI/Pagination/Pagination";
import {getAllAffiliates} from "../../../actions/UserActions";
import ItemsCounterSelector from '../../../components/UI/ItemsCountSelector/ItemsCountSelector';
import {Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom'

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

type Props = {
    getAllAffiliates: Function,
    allAffiliates: {
        loading: boolean,
        affiliates: ?Object,
        error: ?Object
    }
}

type State = {
    perPage: number,
    offset: number,
    sortBy: string,
    orderBy: string
}

const mapStateToProps = state => {
    return {
        allAffiliates: {
            loading: state.userReducer.allAffiliates.loading,
            affiliates: state.userReducer.allAffiliates.affiliates,
            error: state.userReducer.allAffiliates.error
        }
    }
};

const mapActionsToProps = dispatch => {
    return {
        getAllAffiliates: bindActionCreators(getAllAffiliates, dispatch)
    }
};

export class AffiliatesPage extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            perPage: 10,
            offset: 0,
            sortBy: 'created',
            orderBy: 'ASC'
        }
    }

    componentDidMount() {
        this.props.getAllAffiliates(
            this.state.perPage,
            this.state.offset,
            this.state.sortBy,
            this.state.orderBy
        );
    };

    /**
     * @param data
     */
    handlePageClick = (data: {selected: number}) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);

        this.setState({ offset: offset }, () => {
            this.props.getAllAffiliates(
                this.state.perPage,
                offset,
                this.state.sortBy,
                this.state.orderBy
            );
        });
    };

    /**
     * @param item
     */
    handleSelectorChange = (item: number) => {
        this.setState({
            perPage: item
        }, () => {
            this.props.getAllAffiliates(
                item,
                this.state.offset,
                this.state.sortBy,
                this.state.orderBy
            );
        });
    };

    handleOrder = (data: string) => {
        this.setState({
            sortBy: data,
            orderBy: this.state.orderBy === 'ASC' ? 'DESC' : 'ASC'
        }, () => {
            this.props.getAllAffiliates(
                this.state.perPage,
                this.state.offset,
                data,
                this.state.orderBy
            );
        })
    };

    /**
     * @returns {*}
     */
    render() {
        const pageCount = this.props.allAffiliates.affiliates
            ? this.props.allAffiliates.affiliates.count / this.state.perPage
            : 0;

        const availableTableHeading = {
            email: {
                data: 'Email',
                isSorted: true
            },
            period: {
                data: 'Период *',
                isSorted: false
            },
            percentage: {
                data: 'Процент',
                isSorted: false
            },
            affiliate: {
                data: 'Кол-во клиентов',
                isSorted: false
            },
            created: {
                data: 'Дата регистрации',
                isSorted: true
            },
            affiliate_code: {
                data: 'Ссылка',
                isSorted: false
            }
        };

        const getThead = () => {
            let headings = Object.keys(availableTableHeading).map((el) => {
                return (
                    <th key={el}>
                        {availableTableHeading[el].data}
                        {availableTableHeading[el].isSorted ?
                            <span onClick={() => this.handleOrder(el)}>
                                <FontAwesomeIcon icon='sort'/>
                            </span> : null
                        }
                    </th>
                );
            });

            return <tr>{headings}</tr>
        };

        const tableData = (
            this.props.allAffiliates.affiliates ? Object.keys(this.props.allAffiliates.affiliates).map((item) => {
                let partners =  this.props.allAffiliates.affiliates;

                if (partners && partners[item].email) {
                    let data = partners[item];
                    let settings = data.settings;
                    return (
                        <tr key={item}>
                            <td>
                                <Link to={`/affiliates/${data.affiliate_id}`} className="link-to-partner-page">{data.email}</Link>
                            </td>
                            <td>{settings.period || '-'}</td>
                            <td>{settings.percentage || '-'}</td>
                            <td>{data.customers_count}</td>
                            <td>{data.registration_date}</td>
                            <td>{settings.affiliate_code || '-'}</td>
                        </tr>
                    )
                } else {
                    return null;
                }
            }) : null
        );

        return (
            <div id="page-wrapper" className="users-page-wrapper">
                <div className="wrapper">
                    <Table thead={getThead()} tableData={tableData} isLoading={this.props.allAffiliates.loading} />
                    <div className="users-page-note">
                        <small>* В месяцах</small>
                    </div>
                    <div className="pagination-wrapper" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Col xs={12} md={6} lg={6}>
                            <Pagination pageCount={pageCount} onPageChange={this.handlePageClick.bind(this)} />
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <ItemsCounterSelector
                                selectorVariant={[5, 10, 50, 100]}
                                selectorText="Показывать: "
                                onSelectorChange={this.handleSelectorChange.bind(this)}
                                selectedVariant={this.state.perPage}
                            />
                        </Col>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AffiliatesPage);
