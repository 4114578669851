// @flow
import API from '../axios';
import { FETCH_SUBMITTED_FORMS, FETCH_VIEWED_FORMS, FETCH_CLOSED_FORMS } from "../constants/actionTypes";

type Action = {
    type: string,
    payload?: string,
    error?: ?string
}

/**
 * @param action
 * @returns {*}
 */
const dispatchSubmittedForms = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_SUBMITTED_FORMS.REQUEST };
        case 'success':
            return {
                type: FETCH_SUBMITTED_FORMS.SUCCESS,
                payload: action.payload
            };
        case 'failure':
            return {
                type: FETCH_SUBMITTED_FORMS.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @param action
 * @returns {*}
 */
const dispatchViewedForms = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_VIEWED_FORMS.REQUEST };
        case 'success':
            return {
                type: FETCH_VIEWED_FORMS.SUCCESS,
                payload: action.payload
            };
        case 'failure':
            return {
                type: FETCH_VIEWED_FORMS.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @param action
 * @returns {*}
 */
const dispatchCloseForms = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_CLOSED_FORMS.REQUEST };
        case 'success':
            return {
                type: FETCH_CLOSED_FORMS.SUCCESS,
                payload: action.payload
            };
        case 'failure':
            return {
                type: FETCH_CLOSED_FORMS.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @returns {Function}
 */
function fetchSubmittedForms() {
    const url: string = "/forms/analytics?type=submit";
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchSubmittedForms({type: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchSubmittedForms({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchSubmittedForms({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })))
    };
}

/**
 * @returns {Function}
 */
function fetchViewedForms() {
    const url: string = "/forms/analytics?type=view";
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchViewedForms({type: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchViewedForms({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchViewedForms({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })))
    };
}

/**
 * @returns {Function}
 */
function fetchClosedForms() {
    const url: string = "/forms/analytics?type=close";
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchCloseForms({type: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchCloseForms({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchCloseForms({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })))
    };
}


/**
 *
 * @returns {Function}
 */
export function getSubmittedFormAnalytics() {
    return fetchSubmittedForms();
}

/**
 *
 * @returns {Function}
 */
export function getViewedFormAnalytics() {
    return fetchViewedForms();
}

/**
 *
 * @returns {Function}
 */
export function getClosedFormAnalytics() {
    return fetchClosedForms();
}
