// @flow
import React from 'react';
import {Spinner} from "../UI/Spinner";
import LeadsLineChart from "../LineChart/LeadsLineChart/LeadsLineChart";
import Ibox from "../Ibox/Ibox";
import PropTypes from 'prop-types';

type Props = {
    forms: {
        loading: boolean,
        count: Object,
        error: string
    }
}

const LeadsLineChartElement = (props: Props) => {
    let data = null;

    if (props.forms.loading) {
        data =  <Spinner style={{width: '30px', height: '30px'}} />;
    } else if (props.forms.count) {
        data = <LeadsLineChart submittedForms={props.forms.count} />;
    } else {
        data = props.forms.error;
    }

    return (
        <Ibox style={{padding: '15px 20px 20px 20px', minHeight: '287px'}}>
            <div>{data}</div>
        </Ibox>
    )
};

LeadsLineChartElement.propTypes = {
    forms: PropTypes.shape({
        loading: PropTypes.bool,
        count: PropTypes.object,
        error: PropTypes.string
    })
};

export default LeadsLineChartElement;
