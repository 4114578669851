// @flow
const url = window.location.hostname;

/**
 * @returns {string}
 */
export function getApiEndpoint(): string {
    let apiPostfix: string = '/api/v1.0/';

    if (url === 'localhost') {
        return 'https://cabinet.nkazeichev.unibase.xyz' + apiPostfix;
    } else {
        if (url.split('.')[3] === 'xyz') {
            return 'https://cabinet.' + url.split('.')[1] + '.unibase.xyz' + apiPostfix;
        } else {
            return 'https://cabinet.unibase.ru' + apiPostfix;
        }
    }
}

/**
 * @returns {string}
 */
export function getTokenEndpoint(): string {
    let authEndpoint = null;
    let authEndpointPostfix = '/oauth/v2/token';

    if (url === 'localhost') {
        authEndpoint = 'https://cabinet.nkazeichev.unibase.xyz' + authEndpointPostfix;
    } else {
        if (url.split('.')[3] === 'xyz') {
            return 'https://cabinet.' + url.split('.')[1] + '.unibase.xyz' + authEndpointPostfix;
        } else {
            return 'https://cabinet.unibase.ru' + authEndpointPostfix;
        }
    }

    return authEndpoint;
}

/**
 * @returns {string}
 */
export function getHost(): string {
    if (url === 'localhost') {
        return 'https://backoffice.nkazeichev.unibase.xyz';
    } else {
        if (url.split('.')[3] === 'xyz') {
            return 'https://backoffice.' + url.split('.')[1] + '.unibase.xyz';
        } else {
            return 'https://backoffice.unibase.ru';
        }
    }
}

export function getCabinetHost(): string {
    if (url === 'localhost') {
        return 'https://cabinet.nkazeichev.unibase.xyz';
    } else {
        if (url.split('.')[3] === 'xyz') {
            return 'https://cabinet.' + url.split('.')[1] + '.unibase.xyz';
        } else {
            return 'https://cabinet.unibase.ru';
        }
    }
}

export function getHostName(): string {
    return window.location.hostname.split('.')[0];
}
