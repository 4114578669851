// @flow
import React from 'react';
import './LeftMenuItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";

type Props = {
    className: string,
    href: string,
    icon: string,
    name: string
}

/**
 * @param props
 * @returns {*}
 */
export default (props: Props) => {
    return (
        <li className={props.className}>
            <Link to={props.href}>
                <FontAwesomeIcon icon={props.icon}/>
                <span>{props.name}</span>
            </Link>
        </li>
    );
}
