// @flow
import React, { Component } from 'react'
import { Col } from "react-bootstrap";
import './HomePage.scss';
import {getUserProfile} from "../../../actions/UserActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Table from "../../../components/UI/Table/Table";
import Pagination from "../../../components/UI/Pagination/Pagination";
import ItemsCounterSelector from "../../../components/UI/ItemsCountSelector/ItemsCountSelector";

type State = {
    perPage: number,
    offset: number,
    sortBy: string,
    orderBy: string
}

type Props = {
    loading: boolean,
    user: any,
    error: ?Object,
    getUserProfile: Function
}

const mapStateToProps = state => {
    return {
        loading: state.userReducer.userProfile.loading,
        user: state.userReducer.userProfile.user,
        error: state.userReducer.userProfile.error,
    }
};

const mapActionToProps = dispatch => {
    return {
        getUserProfile: bindActionCreators(getUserProfile, dispatch)
    }
};

class HomePage extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            perPage: 10,
            offset: 0,
            sortBy: 'begin_date',
            orderBy: 'DESC'
        }
    }
    componentDidMount(): void {
        this.props.getUserProfile({
            perPage: this.state.perPage,
            offset: this.state.offset,
            sortBy: this.state.sortBy,
            orderBy: this.state.orderBy
        });
    }

    /**
     * @param data
     */
    handlePageClick = (data: {selected: number}) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);

        this.setState({ offset: offset }, () => {
            this.props.getUserProfile({
                perPage: this.state.perPage,
                offset: offset,
                sortBy: this.state.sortBy,
                orderBy: this.state.orderBy
            });
        });
    };

    /**
     * @param item
     */
    handleSelectorChange = (item: number) => {
        this.setState({
            perPage: item
        }, () => {
            this.props.getUserProfile({
                perPage: item,
                offset: this.state.offset,
                sortBy: this.state.sortBy,
                orderBy: this.state.orderBy
            });
        });
    };

    handleOrder = (data: string) => {
        this.setState({
            sortBy: data,
            orderBy: this.state.orderBy === 'ASC' ? 'DESC' : 'ASC'
        }, () => {
            this.props.getUserProfile({
                perPage: this.state.perPage,
                offset: this.state.offset,
                sortBy: data,
                orderBy: this.state.orderBy
            });
        })
    };

    render() {
        const pageCount = this.props.user
            ? this.props.user.customers.count / this.state.perPage
            : 0;

        const availableTableHeading = {
            customer_email: {
                data: 'Email',
                isSorted: true
            },
            tariff_name: {
                data: 'Тариф',
                isSorted: true
            },
            total_amount: {
                data: 'Всего оплатил',
                isSorted: false
            },
            begin_date: {
                data: 'Дата начала',
                isSorted: true
            },
            end_date: {
                data: 'Дата окончания',
                isSorted: true
            }
        };

        const getThead = () => {
            let headings = Object.keys(availableTableHeading).map((el) => {
                return (
                    <th key={el}>
                        {availableTableHeading[el].data}
                        {availableTableHeading[el].isSorted ?
                            <span onClick={() => this.handleOrder(el)}>
                                <FontAwesomeIcon icon='sort'/>
                            </span> : null
                        }
                    </th>
                );
            });

            return <tr>{headings}</tr>
        };

        const tableData = (
            this.props.user ? Object.keys(this.props.user.customers).map((item) => {
                let customer =  this.props.user.customers[item];
                if (this.props.user.customers.count > 0) {
                    if (item !== 'count') {
                        return (
                            <tr key={item}>
                                <td>{customer.customer_email}</td>
                                <td>{customer.tariff}</td>
                                <td>{customer.total_amount} {'\u20bd'}</td>
                                <td>{customer.begin_date}</td>
                                <td>{customer.end_date}</td>
                            </tr>
                        )
                    } else {
                        return null;
                    }
                } else {
                    return (
                        <tr key={item}>
                            <td>Ничего не найдено!</td>
                        </tr>
                    );
                }
            }) : null
        );

        return (
            <div id="page-wrapper">
                <div className="wrapper">
                    <Table isLoading={this.props.loading} thead={getThead()} tableData={tableData} />
                    <div className="pagination-wrapper" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px'}}>
                        <Col xs={12} md={6} lg={6}>
                            <Pagination pageCount={pageCount} onPageChange={this.handlePageClick.bind(this)} />
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <ItemsCounterSelector
                                selectorVariant={[5, 10, 50, 100]}
                                selectorText="Показывать: "
                                onSelectorChange={this.handleSelectorChange.bind(this)}
                                selectedVariant={this.state.perPage}
                            />
                        </Col>
                    </div>
                </div>
            </div>
        );
    }
}

HomePage.propTypes = {
    loading: PropTypes.bool,
    user: PropTypes.object,
    error: PropTypes.object,
    getUserProfile: PropTypes.func
};

export default connect(mapStateToProps, mapActionToProps)(HomePage)
