// @flow
import { FETCH_SUBMITTED_FORMS, FETCH_VIEWED_FORMS, FETCH_CLOSED_FORMS } from '../constants/actionTypes';

type State = {
    submittedForms: {
        count: ?string,
        loading: boolean,
        error: ?string
    },
    viewedForms: {
        count: ?string,
        loading: boolean,
        error: ?string
    },
    closedForms: {
        count: ?string,
        loading: boolean,
        error: ?string
    }
}

type Action = {
    type: string,
    payload: string,
    error: ?string
}

export const initialState = {
    submittedForms: {
        count: null,
        loading: false,
        error: null
    },
    viewedForms: {
        count: null,
        loading: false,
        error: null
    },
    closedForms: {
        count: null,
        loading: false,
        error: null
    }
};

const formsReducer = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case FETCH_SUBMITTED_FORMS.REQUEST:
            return {
                ...state,
                submittedForms: {
                    loading: true,
                    count: null,
                    error: null
                },
            };
        case FETCH_SUBMITTED_FORMS.SUCCESS:
            return {
                ...state,
                submittedForms: {
                    loading: false,
                    count: action.payload,
                    error: null
                }
            };
        case FETCH_SUBMITTED_FORMS.FAILURE:
            return {
                ...state,
                submittedForms: {
                    loading: false,
                    count: null,
                    error: action.error
                },
            };
        case FETCH_VIEWED_FORMS.REQUEST:
            return {
                ...state,
                viewedForms: {
                    loading: true,
                    count: null,
                    error: null
                },
            };
        case FETCH_VIEWED_FORMS.SUCCESS:
            return {
                ...state,
                viewedForms: {
                    loading: false,
                    count: action.payload,
                    error: null
                },
            };
        case FETCH_VIEWED_FORMS.FAILURE:
            return {
                ...state,
                viewedForms: {
                    loading: false,
                    count: null,
                    error: action.error
                },
            };
        case FETCH_CLOSED_FORMS.REQUEST:
            return {
                ...state,
                closedForms: {
                    loading: true,
                    count: null,
                    error: null
                },
            };
        case FETCH_CLOSED_FORMS.SUCCESS:
            return {
                ...state,
                closedForms: {
                    loading: false,
                    count: action.payload,
                    error: null
                },
            };
        case FETCH_CLOSED_FORMS.FAILURE:
            return {
                ...state,
                closedForms: {
                    loading: false,
                    count: null,
                    error: action.error
                },
            };
        default:
            return state;
    }
};

export default formsReducer;
