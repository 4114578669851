import React from 'react';
import { Line } from 'react-chartjs-2';
import { CHART_LABELS } from "../../constants/constants";


const LineChart = (props) => {
    const data = {
        labels: props.labels ? props.labels : CHART_LABELS,
        datasets: props.datasets
    };

    const options = {
        responsive: true,
        tooltips: {
            mode: 'x',
            intersect: false
        }
    };

    return (
        <Line data={data} legend={{display: false}} options={options}/>
    );
};

export default LineChart;
