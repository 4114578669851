// @flow
import API from '../axios';
import {
    FETCH_ACTIVE_USER_COUNT,
    FETCH_USER_COUNT,
    FETCH_PAID_USERS_COUNT,
    FETCH_ALL_USERS,
    FETCH_ALL_AFFILIATES,
    FETCH_AFFILIATE,
    FETCH_USER_PROFILE
} from "../constants/actionTypes";

type UserCountAction = {
    countType: string,
    actionType: string,
    payload?: string,
    error?: ?string
}

type Action = {
    +type: string,
    payload?: string,
    error?: ?string
}

/**
 * @param action
 */
const dispatchUserCount = (action: UserCountAction): Object => {
    if (action.countType === 'active') {
        switch (action.actionType) {
            case 'request':
                return { type: FETCH_ACTIVE_USER_COUNT.REQUEST };
            case 'success':
                return {
                    type: FETCH_ACTIVE_USER_COUNT.SUCCESS,
                    payload: action.payload
                };
            case 'failure':
                return {
                    type: FETCH_ACTIVE_USER_COUNT.FAILURE,
                    error: action.error
                };
            default:
                return {};
        }
    } else {
        switch (action.actionType) {
            case 'request':
                return { type: FETCH_USER_COUNT.REQUEST };
            case 'success':
                return {
                    type: FETCH_USER_COUNT.SUCCESS,
                    payload: action.payload
                };
            case 'failure':
                return {
                    type: FETCH_USER_COUNT.FAILURE,
                    error: action.error
                };
            default:
                return {};
        }
    }
};

/**
 * @param action
 * @returns {*}
 */
const dispatchPaidUsersCount = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_PAID_USERS_COUNT.REQUEST };
        case 'success':
            return {
                type: FETCH_PAID_USERS_COUNT.SUCCESS,
                payload: action.payload
            };
        case 'failure':
            return {
                type: FETCH_PAID_USERS_COUNT.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @param action
 * @returns {*}
 */
const dispatchAllUsers = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_ALL_USERS.REQUEST };
        case 'success':
            return {
                type: FETCH_ALL_USERS.SUCCESS,
                users: action.payload
            };
        case 'failure':
            return {
                type: FETCH_ALL_USERS.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @param action
 * @returns {*}
 */
const dispatchAllAffiliates = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_ALL_AFFILIATES.REQUEST };
        case 'success':
            return {
                type: FETCH_ALL_AFFILIATES.SUCCESS,
                affiliates: action.payload
            };
        case 'failure':
            return {
                type: FETCH_ALL_AFFILIATES.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @param action
 * @returns {*}
 */
const dispatchAffiliate = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_AFFILIATE.REQUEST };
        case 'success':
            return {
                type: FETCH_AFFILIATE.SUCCESS,
                affiliate: action.payload
            };
        case 'failure':
            return {
                type: FETCH_AFFILIATE.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @param action
 * @returns {*}
 */
const dispatchUserProfile = (action: Action): Object => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_USER_PROFILE.REQUEST };
        case 'success':
            return {
                type: FETCH_USER_PROFILE.SUCCESS,
                user: action.payload
            };
        case 'failure':
            return {
                type: FETCH_USER_PROFILE.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 * @param params ("active"/"total")
 */
function fetchUsersCount(params: {countType: string}) {
    const url: string = "/users/count?count_type=" + params.countType;
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchUserCount({countType: params.countType, actionType: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(
                    dispatchUserCount(
                        {
                            countType: params.countType,
                            actionType: 'success',
                            payload: response.data
                        }
                    ));
            })
            .catch(error => dispatch(
                dispatchUserCount(
                    {
                        countType: params.countType,
                        actionType: 'failure',
                        error: error.response ? error.response.data.error_description : null
                    }
                )));
    }
}

/**
 * @returns {Function}
 */
function fetchPaidUsersCount() {
    const url: string = "/users/paid";
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchPaidUsersCount({type: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchPaidUsersCount({type:'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchPaidUsersCount({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })))
    };
}

/**
 * @param params
 * @returns {Function}
 */
function fetchAllUsers(params: {perPage?: ?string, offset?: ?string}) {
    const url: string = "/users/info?limit=" + (params.perPage ? params.perPage : '10') + '&offset=' + (params.offset ? params.offset : '0');
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchAllUsers({type: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchAllUsers({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchAllUsers({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })))
    }
}

/**
 * @param params
 * @returns {Function}
 */
function fetchAllAffiliates(params: {perPage?: ?string, offset?: ?string, sortBy: ?string, orderBy: ?string}) {
    const url: string = "/users/all-affiliates?limit="
        + (params.perPage ? params.perPage : '10')
        + '&offset=' + (params.offset ? params.offset : '')
        + '&sort_by=' + (params.sortBy ? params.sortBy : 'id')
        + '&order_by=' + (params.orderBy ? params.orderBy : 'ASC');
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchAllAffiliates({type: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchAllAffiliates({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchAllAffiliates({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })));
    }
}

/**
 * @param params
 * @returns {Function}
 */
function fetchAffiliate(params) {
    const url: string = "/users/affiliate/" + params.id;
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchAffiliate({type: 'request'}));
        await API.get(url)
            .then((response) => {
                dispatch(dispatchAffiliate({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchAffiliate({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })));
    }
}

/**
 * @param params
 * @returns {Function}
 */
function fetchUserProfile(params) {
    const url: string = "/users/profile?limit="
        + (params.perPage ? params.perPage : '10')
        + '&offset=' + (params.offset ? params.offset : '0')
        + '&sort_by=' + (params.sortBy ? params.sortBy : 'id')
        + '&order_by=' + (params.orderBy ? params.orderBy : 'DESC');
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchUserProfile({type: 'request'}));
        await API.get(url)
            .then((response) => {
                dispatch(dispatchUserProfile({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchUserProfile({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })))
    }

}

/**
 * @returns {function(*)}
 */
export function getActiveUsersCount() {
    return fetchUsersCount({countType: 'active'});
}

/**
 * @returns {function(*)}
 */
export function getTotalUsersCount() {
    return fetchUsersCount({countType: 'total'});
}

/**
 * @returns {*}
 */
export function getPaidUsersCount() {
    return fetchPaidUsersCount();
}

/**
 *
 * @param perPage
 * @param offset
 * @returns {*}
 */
export function getAllUsers(perPage: string, offset: string) {
    return fetchAllUsers({perPage: perPage, offset: offset});
}

/**
 * @returns {Function}
 */
export function getAllAffiliates(perPage: string, offset: string, sortBy: string, orderBy: string) {
    return fetchAllAffiliates({perPage: perPage, offset: offset, sortBy: sortBy, orderBy: orderBy})
}

/**
 * @param params
 * @returns {Function}
 */
export function getAffiliateById(params: {id: string}) {
    return fetchAffiliate(params);
}

/**
 * @returns {Function}
 */
export function getUserProfile(params: {perPage: string, offset: string, sortBy: string, orderBy: string}) {
    return fetchUserProfile(params);
}
