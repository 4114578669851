/**
 * Common
 */
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

function createRequestTypes(base) {
    const requestType = {};
    [REQUEST, SUCCESS, FAILURE].forEach(type => {
        requestType[type] = `${base}_${type}`;
    });
    return requestType;
}

/**
 * USERS
 */
export const FETCH_ACTIVE_USER_COUNT = createRequestTypes('FETCH_ACTIVE_USER_COUNT');
export const FETCH_USER_COUNT = createRequestTypes('FETCH_USER_COUNT');
export const FETCH_PAID_USERS_COUNT = createRequestTypes('FETCH_PAID_USERS_COUNT');
export const FETCH_ALL_USERS = createRequestTypes('FETCH_ALL_USERS');
export const FETCH_ALL_AFFILIATES = createRequestTypes('FETCH_ALL_AFFILIATES');
export const FETCH_AFFILIATE = createRequestTypes('FETCH_AFFILIATE');
export const FETCH_USER_PROFILE = createRequestTypes('FETCH_USER_PROFILE');

/**
 *  BillingRecords
 */
export const FETCH_TOTAL_MONEY_AMOUNT = createRequestTypes('FETCH_TOTAL_MONEY_AMOUNT');

/**
 *  Forms
 */
export const FETCH_SUBMITTED_FORMS = createRequestTypes('FETCH_SUBMITTED_FORMS');
export const FETCH_VIEWED_FORMS = createRequestTypes('FETCH_VIEWED_FORMS');
export const FETCH_CLOSED_FORMS = createRequestTypes('FETCH_CLOSED_FORMS');

/**
 * Emails
 */
export const FETCH_EMAILS_STATUSES = createRequestTypes('FETCH_EMAILS_STATUSES');

/**
 * Sources
 */
export const FETCH_SOURCES_VIEWS = createRequestTypes('FETCH_SOURCES_VIEWS');

/**
 * Auth
 */
export const USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED';
export const USER_IS_UNAUTHENTICATED = 'USER_IS_UNAUTHENTICATED';
export const USER_AUTHENTICATION_FAILED = 'USER_AUTHENTICATION_FAILED';
