// @flow
import React, { Component } from 'react';
import LeftMenuItem from '../../../components/LeftMenuItem/LeftMenuItem';
import LeftMenuIcon from '../../../components/LeftMenuItem/LeftMenuIcon/LeftMenuIcon';
import './LeftMenu.scss';

type Props = {
    href: string
}

class LeftMenu extends Component<Props> {
    render() {
        let href = this.props.href;
        return (
            <nav className="navbar-default navbar-static-side">
                <ul className="navigation">
                    <LeftMenuIcon />
                    <LeftMenuItem name="Главная" className={href === '/' ? "active" : "" } icon="home" href="/"/>
                    <LeftMenuItem name="Выйти" className={href === '/logout' ? "active" : "" } icon="sign-out-alt" href="/logout"/>
                </ul>
            </nav>
        );
    }
}

export default LeftMenu
