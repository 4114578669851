// @flow
import React from 'react';
import './PersonInfoWidget.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type  Props = {
    heading: ?string,
    style: Object,
    data: Object
}

export default (props: Props) => {
    return (
        <div className="widget" style={props.style}>
            <h2>
                {props.heading}
            </h2>
            <ul className="list-unstyled m-t-md">
                {
                    props.data ? Object.keys(props.data).map((key) => {
                        const icon = props.data[key].icon,
                            label = props.data[key].label,
                            text = props.data[key].text;

                        if (icon && label && text) {
                            return (
                                <li key={key}>
                                    <FontAwesomeIcon icon={icon} />
                                    <label>{label}</label>
                                    <span>{text}</span>
                                </li>
                            );
                        } else {
                            return null;
                        }
                    }) : null
                }
            </ul>

        </div>
    );
};
