import React from 'react';
import LineChart from '../LineChart';

/**
 * @param analytic
 * @param type
 * @returns {any[][]}
 */
const getEmailAnalytic = (analytic, type) => {
      return Object.keys(analytic).map(function(date) {
          let count = [];
          if (Array.isArray(analytic[date])) {
              analytic[date].forEach((key) => {
                  if (type === key.message) {
                      count = key.count;
                  }
                  return true;
              });
          } else {
              count = analytic[date];
          }

          return [date, count];
      });
};

/**
 * @param analytic
 */
const getAnalyticsCount = (analytic) => {
    return analytic.map((key) => {
        return key[1];
    });
};

/**
 * @param props
 * @returns {*}
 * @constructor
 */
const EmailsLineChart = (props) => {
    const emailAnalytics = props.emailsAnalytics;

    /** Кол-во переходов по ссылке в письме. Руками обрезается массив для отображения ТОЛЬКО последних 7 дней */
    let clickedAnalytics = getEmailAnalytic(emailAnalytics, 'email.status.clicked');
    let clickedCount = getAnalyticsCount(clickedAnalytics);

    let labels = clickedAnalytics.map((key) => {
        return key[0];
    });

    /** Кол-во доставленных писем */
    let delivered = getEmailAnalytic(emailAnalytics, 'email.status.delivered');
    let deliveredCount = getAnalyticsCount(delivered);

    /** Кол-во HardBounced */
    let hardBounced = getEmailAnalytic(emailAnalytics, 'email.status.hard_bounced');
    let hardBouncedCount = getAnalyticsCount(hardBounced);

    /** Кол-во SoftBounced */
    let softBounced = getEmailAnalytic(emailAnalytics, 'email.status.soft_bounced');
    let softBouncedCount = getAnalyticsCount(softBounced);

    /** Кол-во открыто */
    let opened = getEmailAnalytic(emailAnalytics, 'email.status.opened');
    let openedCount = getAnalyticsCount(opened);

    /** Кол-во отправленных */
    let sent = getEmailAnalytic(emailAnalytics, 'email.status.sent');
    let sentCount = getAnalyticsCount(sent);


    const datasetes = [
        {
            label: 'Отправлено',
            fill: false,
            backgroundColor: 'rgba(28,132,198,0.4)',
            borderColor: 'rgba(28,132,198,0.4)',
            pointBorderColor: 'rgba(28,132,198,0.4)',
            pointBackgroundColor: 'rgba(28,132,198,0.4)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(28,132,198,0.4)',
            pointHoverBorderColor: 'rgba(28,132,198,0.4)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: sentCount
        },
        {
            label: 'Доставлено',
            fill: false,
            backgroundColor: 'rgba(28,132,198,1)',
            borderColor: 'rgba(28,132,198,1)',
            pointBorderColor: 'rgba(28,132,198,1)',
            pointBackgroundColor: 'rgba(28,132,198,1)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(28,132,198,1)',
            pointHoverBorderColor: 'rgba(28,132,198,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: deliveredCount
        },
        {
            label: 'Открыто',
            fill: false,
            backgroundColor: 'rgba(26,179,148,0.4)',
            borderColor: 'rgba(26,179,148,0.4)',
            pointBorderColor: 'rgba(26,179,148,0.4)',
            pointBackgroundColor: 'rgba(26,179,148,0.4)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(26,179,148,0.4)',
            pointHoverBorderColor: 'rgba(26,179,148,0.4)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: openedCount
        },
        {
            label: 'Переходов',
            fill: false,
            backgroundColor: 'rgba(26,179,148,1)',
            borderColor: 'rgba(26,179,148,1)',
            pointBorderColor: 'rgba(26,179,148,1)',
            pointBackgroundColor: 'rgba(26,179,148,1)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(26,179,148,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: clickedCount
        },
        {
            label: 'Soft Bounced',
            fill: false,
            backgroundColor: 'rgba(248,172,89,1)',
            borderColor: 'rgba(248,172,89,1)',
            pointBorderColor: 'rgba(248,172,89,1)',
            pointBackgroundColor: 'rgba(248,172,89,1)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(248,172,89,1)',
            pointHoverBorderColor: 'rgba(248,172,89,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: softBouncedCount
        },
        {
            label: 'Hard Bounced',
            fill: false,
            backgroundColor: 'rgba(237,85,101,1)',
            borderColor: 'rgba(237,85,101,1)',
            pointBorderColor: 'rgba(237,85,101,1)',
            pointBackgroundColor: 'rgba(237,85,101,1)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(237,85,101,1)',
            pointHoverBorderColor: 'rgba(237,85,101,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: hardBouncedCount
        }
    ];

    return (
        <LineChart
            datasets={datasetes}
            labels={labels}
        />
    )
};

export default EmailsLineChart;
