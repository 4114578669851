import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {Provider} from "react-redux";
import Routes from './routes';
import store, {persistor} from './store/index';
import { PersistGate } from 'redux-persist/lib/integration/react';


const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Routes />
        </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

