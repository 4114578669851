// @flow
import { combineReducers, AnyAction } from "redux";
import userReducer from './UserReducer';
import billingRecordsReducer from './BillingRecordsReducer';
import formReducer from './FormsReducer'
import sourceReducer from './SourcesReducer';
import emailReducer from './EmailsReducer';
import authReducer from './AuthReducer';

const rootReducer: (state: any, action: AnyAction) => any = combineReducers({
    userReducer: userReducer,
    billingRecordsReducer: billingRecordsReducer,
    formReducer: formReducer,
    emailReducer: emailReducer,
    sourceReducer: sourceReducer,
    authReducer: authReducer
});

export default rootReducer;
