// @flow
import React from 'react';
import './IboxContent.scss';
import PropTypes from 'prop-types';

type Props = {
    data?: string,
    symbol?: ?string,
    description?: ?string
}

const IboxContent = (props: Props) => (
    <div className="ibox-content">
        <h1>{props.data} {props.symbol}</h1>
        <small>{props.description}</small>
    </div>
);

IboxContent.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    symbol: PropTypes.string,
    description: PropTypes.string
};

export default IboxContent;
