// @flow
import React, {Component} from 'react';
import PersonInfoWidget from '../../../../components/Widgets/PersonInfoWidget/PersonInfoWidget';
import { withRouter } from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';
import IconWithDataWidget from '../../../../components/Widgets/IconWithDataWidget/IconWithDataWidget';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import {getAffiliateById} from "../../../../actions/UserActions";
import Table from "../../../../components/UI/Table/Table";

type Props = {
    loading: boolean,
    match: Object,
    affiliate: {
        settings: {
            affiliate_code: ?string,
            percentage: string,
            period: string
        },
        email: string,
        registration_date: string,
        customers: any,
        tariff: string,
        begin_date: string
    },
    error: ?Object,
    getAffiliate: Function
}

type State = {
    affiliate_id: string
}

const mapStateToProps = state => {
    return {
        loading: state.userReducer.affiliate.loading,
        affiliate: state.userReducer.affiliate.user,
        error: state.userReducer.affiliate.error
    }
};

const mapActionToProps = dispatch => {
    return {
        getAffiliate: bindActionCreators(getAffiliateById, dispatch)
    }
};

export class AffiliatePage extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            affiliate_id: this.props.match.params.id
        };
    }

    componentDidMount(): void {
        this.props.getAffiliate({id: this.props.match.params.id});
    }

    render() {
        let personInfoWidget: {
            [key: string]: {
                icon: string,
                label: string,
                text: ?string
            }
        };

        if (this.props.affiliate) {
            personInfoWidget = {
                '0': {
                    icon: 'clock',
                    label: 'Период:',
                    text: this.props.affiliate.settings.period
                        ? this.props.affiliate.settings.period + ' месяцев'
                        : ''
                },
                '1': {
                    icon: 'percent',
                    label: 'Процент:',
                    text: this.props.affiliate.settings.percentage
                        ? this.props.affiliate.settings.percentage + '%'
                        : ''
                },
                '2': {
                    icon: 'plus',
                    label: 'Зарегистрировался:',
                    text: this.props.affiliate.registration_date
                },
                '3': {
                    icon: 'code',
                    label: 'Ссылка:',
                    text: this.props.affiliate.settings.affiliate_code
                }
            };
        }

        const thead = (
            <tr>
                <th>Email</th>
                <th>Тариф</th>
                <th>Дата регистрации</th>
            </tr>
        );

        const tableData = (
            this.props.affiliate ? Object.keys(this.props.affiliate.customers).map((item) => {
                let customer = this.props.affiliate.customers[item];
                if (this.props.affiliate.customers.count > 0) {
                    return (
                        <tr key={item}>
                            <td>{customer.customer_email}</td>
                            <td>{customer.tariff}</td>
                            <td>{customer.begin_date}</td>
                        </tr>
                    )
                } else {
                    return (
                        <tr key={item}>
                            <td>Ничего не найдено!</td>
                        </tr>
                    )

                }
            }) : null
        );

        const getTotalAmount = () => {
            let totalAmount = 0;
            if (this.props.affiliate)  {
                Object.keys(this.props.affiliate.customers).forEach((item) => {
                    let customerAmount = this.props.affiliate.customers[item].total_amount;
                    if (customerAmount) {
                        totalAmount += customerAmount;
                    }
                })
            }
            return Math.floor(totalAmount);
        };

        return (
            <div id="page-wrapper" className="users-page-wrapper">
                <div className="wrapper">
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <PersonInfoWidget
                                heading={this.props.affiliate ? this.props.affiliate.email : null}
                                data={personInfoWidget}
                                style={{backgroundColor: '#23c6c8', color: '#ffffff'}} />
                        </Col>
                        <Col lg={3} md={3} xs={12}>
                            <IconWithDataWidget
                                icon="user"
                                style={{backgroundColor: '#f8ac59', color: '#ffffff'}}
                                subtext="Всего клиентов"
                                data={this.props.affiliate ? this.props.affiliate.customers.count : null}
                            />
                        </Col>
                        <Col lg={3} md={3} xs={12}>
                            <IconWithDataWidget
                                icon="ruble-sign"
                                style={{backgroundColor: '#f8ac59', color: '#ffffff'}}
                                subtext="Всего заплатили"
                                data={getTotalAmount()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <Table thead={thead} tableData={tableData} isLoading={this.props.loading} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

AffiliatePage.propTypes = {
    loading: PropTypes.bool,
    match: PropTypes.object,
    affiliate: PropTypes.shape({
        settings: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        registration_date: PropTypes.string,
        customers: PropTypes.object,
        customer_email: PropTypes.string,
        tariff: PropTypes.string,
        begin_date: PropTypes.string
    }),
    error: PropTypes.object,
    getAffiliate: PropTypes.func
};

export default connect(mapStateToProps, mapActionToProps)(withRouter(AffiliatePage));
