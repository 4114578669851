import React, { Component } from 'react';
import './UsersPage.scss';
import Pagination from '../../../components/UI/Pagination/Pagination';

import { getAllUsers } from "../../../actions/UserActions";

import Table from '../../../components/UI/Table/Table';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { getCabinetHost } from "../../../_helpers/endpoints";

const mapStateToProps = (state) => {
    return {
        userReducer: {
            allUsers: {
                users: state.userReducer.allUsers.users,
                loading: state.userReducer.allUsers.loading,
                error: state.userReducer.allUsers.error
            }
        }
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        getAllUsers: bindActionCreators(getAllUsers, dispatch)
    }
};


class UsersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            perPage: 10,
            offset: 0
        }
    }

    componentDidMount() {
        this.props.getAllUsers(this.state.perPage, this.state.offset);
    }

    handlePageClick(data) {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);

        this.setState({ offset: offset }, () => {
            this.props.getAllUsers(this.state.perPage, offset);
        });
    }

    handleButtonClick(email, event) {
        window.open(getCabinetHost() + '?_switch_user=' + email, '_blank');
        event.preventDefault();
    }

    render() {
        const pageCount = this.props.userReducer.allUsers.users ? (this.props.userReducer.allUsers.users['count'] / 10) : 0;
        const thead = (
            <tr>
                <th>Email</th>
                <th>Дата регистрации</th>
                <th>Последний вход</th>
                <th>Тариф</th>
                <th>Дата окончания</th>
                <th>Оставшиеся контакты</th>
                <th>Количество посетителей *</th>
                <th>Показов форм *</th>
                <th>Количество контактов *</th>
                <th>&nbsp;</th>
            </tr>
        );

        const tableData = (
            this.props.userReducer.allUsers.users ? Object.keys(this.props.userReducer.allUsers.users['result']).map((item) => {
                let data = this.props.userReducer.allUsers.users['result'];
                let tariffDate = Date.parse(data[item].tariff_end_raw);
                let lessThanSeven = tariffDate - (1000 * 60 * 60 * 24 * 7);
                let lessThenThree = tariffDate - (1000 * 60 * 60 * 24 * 3);
                let DateNow = Date.now();
                let tariffRowClass = '';


                if (DateNow >= lessThanSeven && DateNow < lessThenThree) {
                    tariffRowClass = 'tariff-info';
                } else if (DateNow >= lessThenThree ) {
                    tariffRowClass = 'tariff-danger';
                }

                return (
                    <tr className={tariffRowClass} key={item}>
                        <td>{data[item].email}</td>
                        <td>{data[item].registration_date || ''}</td>
                        <td>{data[item].last_login || ''}</td>
                        <td>{data[item].tariff}</td>
                        <td>{data[item].tariff_end}</td>
                        <td>{data[item].tariff_contacts_value}</td>
                        <td>{data[item].source_visitors_count}</td>
                        <td>{data[item].form_views_count}</td>
                        <td>{data[item].leads_count}</td>
                        <td>
                            <button
                                type="button"
                                className="btn btn-sm"
                                style={{color: '#fff', backgroundColor: '#1ab394', borderColor: '#1ab394'}}
                                onClick={(e) => this.handleButtonClick(data[item].email, e)}
                            >
                                Войти
                            </button>
                        </td>
                    </tr>
                )
            }) : null
        );

        return (
            <div id="page-wrapper" className="users-page-wrapper">
                <div className="wrapper">
                    <Table thead={thead} tableData={tableData} isLoading={this.props.userReducer.allUsers.loading}/>
                    <div className="users-page-note">
                        <small>* Данные за прошедшие 24 часа</small>
                    </div>
                    <Pagination pageCount={pageCount} onPageChange={this.handlePageClick.bind(this)} />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(UsersPage)
