// @flow
import API from '../axios';
import {getHostName, getTokenEndpoint} from '../_helpers/endpoints';
import {OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET} from "../constants/constants";
import history from '../history';
import {
    USER_IS_AUTHENTICATED,
    USER_IS_UNAUTHENTICATED,
    USER_AUTHENTICATION_FAILED
} from "../constants/actionTypes";

type Credentials = {
    username: string,
    password: string
}

/**
 * @param credentials
 * @returns {Function}
 */
export const login = (credentials: Credentials) => {
    return async (dispatch: any): Promise<any> => {
        await API
            .post(getTokenEndpoint(), {
                username: credentials.username,
                password: credentials.password,
                client_id: OAUTH_CLIENT_ID,
                client_secret: OAUTH_CLIENT_SECRET,
                grant_type: 'password'
            })
            .then(response => {
                if (isAccessAllowed(response.data.user)) {
                    dispatch({type: USER_IS_AUTHENTICATED, payload: {...response.data, created_at: Date.now()}});
                    history.push('/')
                } else {
                    throw new Error('Недостаточно прав для просмотра контента');
                }
            })
            .catch(e => {
                dispatch({type: USER_AUTHENTICATION_FAILED, payload: e});
                let error = {};

                if (e.response) {
                    error.message = e.response.data.error_description;
                    throw error;
                }

                throw e;
            });
    }
};

/**
 * @param user
 * @returns {*}
 */
const isAccessAllowed = (user: Object) => {
    if (user.hasOwnProperty('roles')) {
        if (getHostName() === 'affiliate') {
            return user.roles.includes('ROLE_AFFILIATE');
        } else {
            return user.roles.includes('ROLE_ADMIN');
        }
    }
};

/**
 * @returns {Function}
 */
export const logout = () => {
    return async (dispatch: any): Promise<any> => {
        dispatch({type: USER_IS_UNAUTHENTICATED});
        history.push('/login');
    }
};

/**
 * @param grantType
 * @returns {string}
 */
export const getAuthEndpoint = (grantType: string = 'password'): string => {
    return getTokenEndpoint() + '?client_id=' + OAUTH_CLIENT_ID + '&client_secret=' + OAUTH_CLIENT_SECRET + '&grant_type=' + grantType;
};
