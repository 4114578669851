import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import LoginPage from "./containers/LoginPage/LoginPage";
import NotFound from "./containers/NotFound/NotFound";
import LogoutPage from "./containers/LogoutPage/LogoutPage";
import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute';
import UsersPage from './containers/BackOffice/UsersPage/UsersPage';
import PartnerHomePage from "./containers/BackOffice/HomePage/HomePage";
import AffiliateHomePage from './containers/Affiliate/HomePage/HomePage';
import {getHostName} from "./_helpers/endpoints";
import PartnersPage from "./containers/BackOffice/AffiliatesPage/AffiliatesPage";
import AffiliatePage from "./containers/BackOffice/AffiliatesPage/AffiliatePage/AffiliatePage";

const Routes = () => {
    if (getHostName() === 'backoffice') {
        return (
            <Router history={history}>
                <Switch>
                    <AuthenticatedRoute path="/" exact={true} fallback="/login" component={() => <PartnerHomePage />} />
                    <AuthenticatedRoute path="/users" exact={true} fallback="/login" component={() => <UsersPage />} />
                    <AuthenticatedRoute path="/affiliates" exact={true} fallback="/login" component={() => <PartnersPage />} />
                    <AuthenticatedRoute path="/affiliates/:id" fallback="/login" component={() => <AffiliatePage />} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/logout" component={LogoutPage} />
                    <Route component={NotFound} />
                </Switch>
            </Router>
        );
    } else {
        return (
            <Router history={history}>
                <Switch>
                    <AuthenticatedRoute path="/" exact={true} fallback="/login" component={() => <AffiliateHomePage />} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/logout" component={LogoutPage} />
                    <Route component={NotFound} />
                </Switch>
            </Router>
        );
    }
};

export default Routes;
