import React, { Component } from 'react';
import {logout} from '../../actions/AuthActions';
import { Redirect } from "react-router-dom";
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";

class LogoutPage extends Component {
    componentWillMount() {
        this.props.logout();
    }

    render() {
        return (
            <Redirect to="/login" />
        )
    }
}

const mapActionsToProps = dispatch => {
    return {
        logout: bindActionCreators(logout, dispatch)
    }
};

export default connect(null, mapActionsToProps)(LogoutPage);
