import {createStore, applyMiddleware } from "redux";
import reducers from "../reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['authReducer']
};

const pReducer = persistReducer(persistConfig, reducers);
let store = null;

if (process.env.NODE_ENV === 'production') {
    store = createStore(pReducer, applyMiddleware(thunk));
} else {
    store = createStore(pReducer, composeWithDevTools(applyMiddleware(thunk)));
}

export default store;
export const persistor = persistStore(store);
