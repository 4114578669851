// @flow
import React from 'react';
import {Spinner} from "../UI/Spinner";
import {FormattedNumber} from "../UI/FormattedNumber";
import Ibox from "../Ibox/Ibox";
import PropTypes from 'prop-types';

type Props = {
    users: {
        loading: boolean,
        count: string,
        error: Object
    }
}

const TotalUsersCount = (props: Props) => {
    let data = null;

    if (props.users.loading) {
        data = <Spinner style={{width: '30px', height: '30px'}} />;
    } else if (props.users.count) {
        data = <FormattedNumber data={props.users.count} />;
    } else {
        data = props.users.error;
    }

    return (
        <Ibox
            name="Всего"
            style={{minHeight: '120px'}}
            data={data}
        />
    )
};

TotalUsersCount.propTypes = {
    users: PropTypes.shape({
        loading: PropTypes.bool,
        count: PropTypes.number,
        error: PropTypes.string
    })
};

export default TotalUsersCount;
