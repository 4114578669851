import React from 'react';
import LineChart from '../LineChart';

const getAnalytics = (data) => {
    let analytics = [];
    let dates = [];

    if (data) {
        Object.keys(data).forEach(function (key) {
            analytics.push(data[key]);
            dates.push(key);
        });
    }

    return [dates, analytics];
};

const LeadsLineChart = (props) => {
    const datasetes = [
            {
                label: 'Контакты',
                backgroundColor: 'rgba(28,132,198,0.8)',
                borderColor: 'rgba(28,132,198,0.8)',
                pointBorderColor: 'rgba(28,132,198,0.8)',
                pointBackgroundColor: 'rgba(28,132,198,0.8)',
                pointHoverBackgroundColor: 'rgba(28,132,198,0.8)',
                pointHoverBorderColor: 'rgba(28,132,198,0.8)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: getAnalytics(props.submittedForms.formSubmits)[1]
            },
            {
                label: 'Контакты на прошлой неделе',
                backgroundColor: 'rgba(28,132,198,0.4)',
                borderColor: 'rgba(28,132,198,0.4)',
                pointBorderColor: 'rgba(28,132,198,0.4)',
                pointBackgroundColor: 'rgba(28,132,198,0.4)',
                pointHoverBackgroundColor: 'rgba(28,132,198,0.4)',
                pointHoverBorderColor: 'rgba(28,132,198,0.4)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: getAnalytics(props.submittedForms.formSubmitsPrevious)[1]
            }
        ];

    return (
        <LineChart
            datasets={datasetes}
            labels={getAnalytics(props.submittedForms.formSubmits)[0]}
        />
    );
};

export default LeadsLineChart;
