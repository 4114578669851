// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Spinner = (props: {style?: Object}) => {
    return (
        <span style={{display: 'flex', justifyContent: 'center', justifyItems: 'center'}}>
            <i>
                <FontAwesomeIcon icon="spinner" spin style={props.style} className="fontawesome-spinner"/>
            </i>
        </span>
    );
};
