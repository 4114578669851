// @flow
import React from 'react';
import {Spinner} from "../UI/Spinner";
import SourcesLineChart from "../LineChart/SourcesLineChart/SourcesLineChart";
import Ibox from "../Ibox/Ibox";
import PropTypes from 'prop-types';

type Props = {
    sources: {
        loading: boolean,
        views: {
            current: Object,
            previous: Object
        },
        visitors: {
            current: Object,
            previous: Object
        },
        error: string
    }
}

const SourcesViewLineChartElement = (props: Props) => {
    let data = null;

    if (props.sources.loading) {
        data = <Spinner style={{width: '30px', height: '30px'}} />;
    } else if (
        props.sources.views.current
        && props.sources.views.previous
        && props.sources.visitors.current
        && props.sources.visitors.previous
    ) {
        const pageViews = props.sources.views;
        const pageVisitors = props.sources.visitors;
        data =  <SourcesLineChart pageViews={pageViews} pageVisitors={pageVisitors} />
    } else {
        data = props.sources.error;

    }

    return (
        <Ibox style={{padding: '15px 20px 20px 20px', minHeight: '287px'}}>
            {data}
        </Ibox>
    )
};

SourcesViewLineChartElement.propTypes = {
    sources: PropTypes.shape({
        loading: PropTypes.bool,
        views: PropTypes.shape({
            current: PropTypes.object,
            previous: PropTypes.object
        }),
        visitors: PropTypes.shape({
            current: PropTypes.object,
            previous: PropTypes.object
        }),
        error: PropTypes.string
    })
};

export default SourcesViewLineChartElement;
