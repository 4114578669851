// @flow
import {USER_IS_AUTHENTICATED, USER_IS_UNAUTHENTICATED, USER_AUTHENTICATION_FAILED} from "../constants/actionTypes";

type State = {
    user: ?string,
    error: Object
}

type Action = {
    type: string,
    payload?: string,
    error?: Object
}

export const initialState = {
    user: null,
    error: null
};

const authReducer = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case USER_IS_AUTHENTICATED:
            return {
                ...state,
                user: action.payload,
                error: null
            };
        case USER_IS_UNAUTHENTICATED:
            return {
                ...state,
                user: null,
                error: null
            };
        case USER_AUTHENTICATION_FAILED:
            return {
                ...state,
                user: null,
                error: action.payload
            };
        default:
            return state;
    }
};

export default authReducer;

