import React from 'react';
import { Route } from 'react-router-dom';
import LeftMenuBackOffice from '../../containers/BackOffice/LeftMenu/LeftMenu';
import LeftMenuAffiliate from '../../containers/Affiliate/LeftMenu/LeftMenu';
import { library } from '@fortawesome/fontawesome-svg-core';
import checkAccess from "../../hoc/checkAccess";
import {getHostName} from "../../_helpers/endpoints";
import {
    faIgloo,
    faHome,
    faSpinner,
    faSignOutAlt,
    faUsers,
    faHandshake,
    faSort,
    faPlus,
    faClock,
    faPercent,
    faCode,
    faUser,
    faRubleSign
} from '@fortawesome/free-solid-svg-icons';

library.add(faIgloo, faHome, faSpinner, faSignOutAlt, faUsers, faHandshake, faSort, faPlus, faClock, faPercent, faCode, faUser, faRubleSign);

/**
 *
 * @param Component
 * @param rest
 * @param props
 * @returns {*}
 * @constructor
 */
const AuthenticatedRoute = ({component: Component, ...rest}) => {
    let AuthComponent = checkAccess(Component);

    return (
        <Route {...rest} render={matchProps => (
            <div id="wrapper">
                {
                    getHostName() === 'backoffice'
                        ? <LeftMenuBackOffice href={matchProps.location.pathname} />
                        : <LeftMenuAffiliate href={matchProps.location.pathname}/>

                }
                <AuthComponent {...matchProps} />
            </div>
        )} />
    );
};

export default AuthenticatedRoute;
