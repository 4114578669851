// @flow
import React from 'react';
import './IconWithDataWidget.scss';
import {Col, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    style: Object,
    icon: string,
    subtext: string,
    data: ?number
}

export default (props: Props) => {
    return (
        <div className="icon-with-data-widget" style={props.style}>
            <Row>
                <Col lg={4}>
                    <FontAwesomeIcon icon={props.icon} />
                </Col>
                <Col lg={8} className="text-right">
                    <span>{props.subtext}</span>
                    <h2 className="font-bold">{props.data}</h2>
                </Col>
            </Row>
        </div>
    );
}
