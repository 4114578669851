// @flow
import React from 'react';
import './ItemsCountSelector.scss';

type Props = {
    selectorVariant: Array<number>,
    selectorText: string,
    selectedVariant: number,
    onSelectorChange: Function
}

export default (props: Props) => {
    return (
        <div className="count-selector-wrapper">
            <div className="text-right">
                <span>{props.selectorText}</span>
                <ul>
                    {
                        props.selectorVariant.map((item) => {
                            let className = 'active item-selector-' + item;

                            return (
                                <li key={item}>
                                    <span
                                        onClick={() => props.onSelectorChange(item)}
                                        className={props.selectedVariant === item ? className : 'item-selector-' + item}
                                    >
                                        {item}
                                    </span>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
}
