// @flow
import {
    FETCH_ACTIVE_USER_COUNT,
    FETCH_USER_COUNT,
    FETCH_PAID_USERS_COUNT,
    FETCH_ALL_USERS,
    FETCH_ALL_AFFILIATES,
    FETCH_AFFILIATE,
    FETCH_USER_PROFILE
} from '../constants/actionTypes';

type State = {
    activeUsers: {
        count: ?string,
        loading: boolean,
        error: ?string
    },
    totalUsers: {
        count: ?string,
        loading: boolean,
        error: ?string
    },
    paidUsers: {
        count: ?string,
        loading: boolean,
        error: ?string
    },
    allUsers: {
        users: ?string,
        loading: boolean,
        error: ?string
    },
    allAffiliates: {
        affiliates: ?string,
        loading: boolean,
        error: ?string
    },
    userProfile: {
        user: ?string,
        loading: boolean,
        error: ?string
    }
}

type Action = {
    type: string,
    payload?: string,
    error?: ?string,
    users?: Object,
    affiliates?: Object,
    affiliate?: Object,
    user?: Object
 }

export const initialState = {
    activeUsers: {
        count: null,
        loading: false,
        error: null
    },
    totalUsers: {
        count: null,
        loading: false,
        error: null
    },
    paidUsers: {
        count: null,
        loading: false,
        error: null
    },
    allUsers: {
        users: null,
        loading: false,
        error: null
    },
    allAffiliates: {
        affiliates: null,
        loading: false,
        error: null
    },
    affiliate: {
        user: null,
        loading: false,
        error: null
    },
    userProfile: {
        user: null,
        loading: false,
        error: null
    }
};
const userReducer = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        /** ACTIVE USERS */
        case FETCH_ACTIVE_USER_COUNT.REQUEST:
            return {
                ...state,
                activeUsers: {
                    loading: true,
                    count: null,
                    error: null
                },
            };
        case FETCH_ACTIVE_USER_COUNT.SUCCESS:
            return {
                ...state,
                activeUsers: {
                    loading: false,
                    count: action.payload,
                    error: null
                },
            };
        case FETCH_ACTIVE_USER_COUNT.FAILURE:
            return {
                ...state,
                activeUsers: {
                    loading: false,
                    count: null,
                    error: action.error
                }
            };
        /** TOTAL USERS */
        case FETCH_USER_COUNT.REQUEST:
            return {
                ...state,
                totalUsers: {
                    loading: true,
                    count: null,
                    error: null
                }
            };
        case FETCH_USER_COUNT.SUCCESS:
            return {
                ...state,
                totalUsers: {
                    loading: false,
                    count: action.payload,
                    error: null
                }
            };
        case FETCH_USER_COUNT.FAILURE:
            return {
                ...state,
                totalUsers: {
                    loading: false,
                    count: null,
                    error: action.error
                }
            };
        /** PAID USERS */
        case FETCH_PAID_USERS_COUNT.REQUEST:
            return {
                ...state,
                paidUsers: {
                    loading: true,
                    count: null,
                    error: null
                }
            };
        case FETCH_PAID_USERS_COUNT.SUCCESS:
            return {
                ...state,
                paidUsers: {
                    loading: false,
                    count: action.payload,
                    error: null
                }
            };
        case FETCH_PAID_USERS_COUNT.FAILURE:
            return {
                ...state,
                paidUsers: {
                    loading: false,
                    count: null,
                    error: action.error
                }
            };
        /** ALL USERS */
        case FETCH_ALL_USERS.REQUEST:
            return {
                ...state,
                allUsers: {
                    loading: true,
                    users: null,
                    error: null
                }
            };
        case FETCH_ALL_USERS.SUCCESS:
            return {
                ...state,
                allUsers: {
                    loading: false,
                    users: action.users,
                    error: null
                }
            };
        case FETCH_ALL_USERS.FAILURE:
            return {
                ...state,
                allUsers: {
                    loading: false,
                    users: null,
                    error: action.error
                }
            };
        /** ALL Partners */
        case FETCH_ALL_AFFILIATES.REQUEST:
            return {
                ...state,
                allAffiliates: {
                    loading: true,
                    affiliates: null,
                    error: null
                }
            };
        case FETCH_ALL_AFFILIATES.SUCCESS:
            return {
                ...state,
                allAffiliates: {
                    loading: false,
                    affiliates: action.affiliates,
                    error: null
                }
            };
        case FETCH_ALL_AFFILIATES.FAILURE:
            return {
                ...state,
                allAffiliates: {
                    loading: false,
                    affiliates: null,
                    error: action.error
                }
            };
        /** Single Partners */
        case FETCH_AFFILIATE.REQUEST:
            return {
                ...state,
                affiliate: {
                    loading: true,
                    user: null,
                    error: null
                }
            };
        case FETCH_AFFILIATE.SUCCESS:
            return {
                ...state,
                affiliate: {
                    loading: false,
                    user: action.affiliate,
                    error: null
                }
            };
        case FETCH_AFFILIATE.FAILURE:
            return {
                ...state,
                affiliate: {
                    loading: false,
                    user: null,
                    error: action.error
                }
            };
        /** User Profile */
        case FETCH_USER_PROFILE.REQUEST:
            return {
                ...state,
                userProfile: {
                    loading: true,
                    user: null,
                    error: null
                }
            };
        case FETCH_USER_PROFILE.SUCCESS:
            return {
                ...state,
                userProfile: {
                    loading: false,
                    user: action.user,
                    error: null
                }
            };
        case FETCH_USER_PROFILE.FAILURE:
            return {
                ...state,
                userProfile: {
                    loading: false,
                    user: null,
                    error: action.error
                }
            };
        /** DEFAULT */
        default:
            return state;
    }
};

export default userReducer;
