// @flow
import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.scss';

type Props = {
    pageCount: number,
    onPageChange: Function
}

export default (props: Props) => {
    return (
        <ReactPaginate
            previousLabel={'Назад'}
            nextLabel={'Вперед'}
            breakLabel={'...'}
            pageCount={props.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={props.onPageChange}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
    );
};
