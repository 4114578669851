import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import './HomePage.scss';


import { getActiveUsersCount, getTotalUsersCount, getPaidUsersCount } from "../../../actions/UserActions";
import { getTotalMoneyAmount } from "../../../actions/BillingRecordsActions";
import { getClosedFormAnalytics, getSubmittedFormAnalytics, getViewedFormAnalytics } from "../../../actions/FormsActions";
import { getEmailsStatuses } from '../../../actions/EmailsActions';
import { getSourcesViews } from "../../../actions/SourcesActions";

import TotalUsersCount from '../../../components/HomePageElements/TotalUsersCount';
import ActiveUsersCount from '../../../components/HomePageElements/ActiveUsersCount';
import PaidUsersCount from '../../../components/HomePageElements/PaidUsersCount';
import TotalMoneyAmount from '../../../components/HomePageElements/TotalMoneyAmount';
import LeadLineChartElement from '../../../components/HomePageElements/LeadsLineChartElement';
import EmailsLineChartElement from '../../../components/HomePageElements/EmailsLineChartElement';
import FormsLineChartElement from '../../../components/HomePageElements/FormsLineChartElement';
import SourcesViewLineChartElement from '../../../components/HomePageElements/SourcesViewLineChartElement';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

const mapStateToProps = state => {
    return {
        users: {
            active: {
                count: state.userReducer.activeUsers.count,
                error: state.userReducer.activeUsers.error,
                loading: state.userReducer.activeUsers.loading,
            },
            total: {
                count: state.userReducer.totalUsers.count,
                error: state.userReducer.totalUsers.error,
                loading: state.userReducer.totalUsers.loading,
            },
            paid: {
                count: state.userReducer.paidUsers.count,
                error: state.userReducer.paidUsers.error,
                loading: state.userReducer.paidUsers.loading,
            }
        },
        billing: {
            moneyAmount: state.billingRecordsReducer.totalAmount,
            loading: state.billingRecordsReducer.loading,
            error: state.billingRecordsReducer.error
        },
        forms: {
            submitted: {
                count: state.formReducer.submittedForms.count,
                loading: state.formReducer.submittedForms.loading,
                error: state.formReducer.submittedForms.error
            },
            viewed: {
                count: state.formReducer.viewedForms.count,
                loading: state.formReducer.viewedForms.loading,
                error: state.formReducer.viewedForms.error
            },
            closed: {
                count: state.formReducer.closedForms.count,
                loading: state.formReducer.closedForms.loading,
                error: state.formReducer.closedForms.error
            }
        },
        emails: {
            count: state.emailReducer.count,
            loading: state.emailReducer.loading,
            error: state.emailReducer.error
        },
        sources: {
            views: {
                current: state.sourceReducer.views.current,
                previous: state.sourceReducer.views.previous
            },
            visitors: {
                current: state.sourceReducer.visitors.current,
                previous: state.sourceReducer.visitors.previous
            },
            loading: state.sourceReducer.loading,
            error: state.sourceReducer.error
        }
    }
};

const mapActionsToProps = (dispatch) => {
    return {
        getActiveUsersCount: bindActionCreators(getActiveUsersCount, dispatch),
        getTotalUsersCount: bindActionCreators(getTotalUsersCount, dispatch),
        getTotalMoneyAmount: bindActionCreators(getTotalMoneyAmount, dispatch),
        getPaidUsersCount: bindActionCreators(getPaidUsersCount, dispatch),
        getSubmittedFormAnalytics: bindActionCreators(getSubmittedFormAnalytics, dispatch),
        getViewedFormAnalytics: bindActionCreators(getViewedFormAnalytics, dispatch),
        getClosedFormAnalytics: bindActionCreators(getClosedFormAnalytics, dispatch),
        getEmailsStatuses: bindActionCreators(getEmailsStatuses, dispatch),
        getSourcesViews: bindActionCreators(getSourcesViews, dispatch)
    }
};

/**
 * Главный экран dashboard-a
 */
class HomePage extends Component {
    componentDidMount() {
        const props = this.props;

        props.getActiveUsersCount();
        props.getTotalUsersCount();
        props.getTotalMoneyAmount();
        props.getPaidUsersCount();
        props.getSubmittedFormAnalytics();
        props.getViewedFormAnalytics();
        props.getClosedFormAnalytics();
        props.getEmailsStatuses();
        props.getSourcesViews();
    }

    render() {
        return (
            <div id="page-wrapper">
                <div className="wrapper">
                    <Row>
                        <Col lg={12}>
                            <h3>Пользователи</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <TotalUsersCount users={this.props.users.total} />
                        </Col>
                        <Col lg={3}>
                            <ActiveUsersCount users={this.props.users.active} />
                        </Col>
                        <Col lg={3}>
                            <PaidUsersCount users={this.props.users.paid} />
                        </Col>
                        <Col lg={3}>
                            <TotalMoneyAmount billing={this.props.billing} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <h3>Контакты</h3>
                            <LeadLineChartElement forms={this.props.forms.submitted} />
                        </Col>
                        <Col lg={6}>
                            <h3>Письма</h3>
                            <EmailsLineChartElement emails={this.props.emails} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <h3>Формы</h3>
                            <FormsLineChartElement forms={this.props.forms} />
                        </Col>
                        <Col lg={6}>
                            <h3>Посетители</h3>
                            <SourcesViewLineChartElement sources={this.props.sources} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(HomePage);
