// @flow
import React from 'react';
import {Spinner} from "../UI/Spinner";
import {FormattedNumber} from "../UI/FormattedNumber";
import Ibox from "../Ibox/Ibox";
import PropTypes from 'prop-types';

type Props ={
    users: {
        loading: boolean,
        count: string,
        error: Object
    }
}

const ActiveUsersCount = (props: Props) => {
    let data = null;

    if (props.users.loading) {
        data =  <Spinner style={{width: '30px', height: '30px'}} />;
    } else if (props.users.count) {
        data =  <FormattedNumber data={props.users.count}/>;
    } else {
        data = props.users.error;
    }

    return (
        <Ibox
            name="Активных"
            style={{minHeight: '120px'}}
            data={data}
        />
    )
};

ActiveUsersCount.propTypes = {
    users: PropTypes.shape({
        loading: PropTypes.bool,
        count: PropTypes.string,
        error: PropTypes.string
    })
};

export default ActiveUsersCount;
