import React from 'react';
import LineChart from '../LineChart';

const getAnalytics = (data) => {
    let analytics = [];
    let dates = [];

    if (data) {
        Object.keys(data).forEach(function (key) {
            analytics.push(data[key]);
            dates.push(key);
        });
    }

    return [dates, analytics];
};

const FormsLineChart = (props) => {
    const datasetes = [
        {
            label: 'Показы',
            fill: false,
            backgroundColor: 'rgba(26,179,148,0.8)',
            borderColor: 'rgba(26,179,148,0.8)',
            pointBorderColor: 'rgba(26,179,148,0.8)',
            pointBackgroundColor: 'rgba(26,179,148,0.8)',
            pointHoverBackgroundColor: 'rgba(26,179,148,0.8)',
            pointHoverBorderColor: 'rgba(220,220,220,0.8)',
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.viewedForms.formViews)[1]
        },
        {
            label: 'Показы на прошлой неделе',
            fill: false,
            backgroundColor: 'rgba(26,179,148,0.4)',
            borderColor: 'rgba(26,179,148,0.4)',
            pointBorderColor: 'rgba(26,179,148,0.4)',
            pointBackgroundColor: 'rgba(26,179,148,0.4)',
            pointHoverBackgroundColor: 'rgba(26,179,148,0.4)',
            pointHoverBorderColor: 'rgba(26,179,148,0.4)',
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.viewedForms.formViewsPrevious)[1]
        },
        {
            label: 'Контакты',
            fill: false,
            backgroundColor: 'rgba(28,132,198,0.8)',
            borderColor: 'rgba(28,132,198,0.8)',
            pointBorderColor: 'rgba(28,132,198,0.8)',
            pointBackgroundColor: 'rgba(28,132,198,0.8)',
            pointHoverBackgroundColor: 'rgba(28,132,198,0.8)',
            pointHoverBorderColor: 'rgba(28,132,198,0.8)',
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.submittedForms.formSubmits)[1]
        },
        {
            label: 'Контакты на прошлой неделе',
            fill: false,
            backgroundColor: 'rgba(28,132,198,0.4)',
            borderColor: 'rgba(28,132,198,0.4)',
            pointBorderColor: 'rgba(28,132,198,0.4)',
            pointBackgroundColor: 'rgba(28,132,198,0.4)',
            pointHoverBackgroundColor: 'rgba(28,132,198,0.4)',
            pointHoverBorderColor: 'rgba(28,132,198,0.4)',
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.submittedForms.formSubmitsPrevious)[1]
        },
        {
            label: 'Закрыто',
            fill: false,
            backgroundColor: 'rgba(181,184,207,0.8)',
            borderColor: 'rgba(181,184,207,0.8)',
            pointBorderColor: 'rgba(181,184,207,0.8)',
            pointBackgroundColor: 'rgba(181,184,207,0.8)',
            pointHoverBackgroundColor: 'rgba(181,184,207,0.8)',
            pointHoverBorderColor: 'rgba(181,184,207,0.8)',
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.closedForms.formCloses)[1]
        },
        {
            label: 'Закрыто на прошлой неделе',
            fill: false,
            backgroundColor: 'rgba(181,184,207,0.4)',
            borderColor: 'rgba(181,184,207,0.4)',
            pointBorderColor: 'rgba(181,184,207,0.4)',
            pointBackgroundColor: 'rgba(181,184,207,0.4)',
            pointHoverBackgroundColor: 'rgba(181,184,207,0.4)',
            pointHoverBorderColor: 'rgba(181,184,207,0.4)',
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.closedForms.formClosesPrevious)[1]
        }
    ];

    return <LineChart
        datasets={datasetes}
        labels={getAnalytics(props.viewedForms.formViews)[0]}
    />
};

export default FormsLineChart;
