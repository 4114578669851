import React from 'react';
import LineChart from '../LineChart';

const getAnalytics = (data) => {
    let analytics = [];
    let dates = [];

    if (data) {
        Object.keys(data).forEach(function (key) {
            analytics.push(data[key]);
            dates.push(key);
        });
    }

    return [dates, analytics];
};

const SourcesLineChart = (props) => {

    const datasetes = [
        {
            label: 'Просмотры',
            fill: false,
            backgroundColor: 'rgba(28,132,198,0.8)',
            borderColor: 'rgba(28,132,198,0.8)',
            pointBorderColor: 'rgba(28,132,198,0.8)',
            pointBackgroundColor: 'rgba(28,132,198,0.8)',
            pointHoverBackgroundColor: 'rgba(28,132,198,0.8)',
            pointHoverBorderColor: 'rgba(28,132,198,0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.pageViews.current)[1]
        },
        {
            label: 'Просмотры на прошлой неделе',
            fill: false,
            backgroundColor: 'rgba(28,132,198,0.4)',
            borderColor: 'rgba(28,132,198,0.4)',
            pointBorderColor: 'rgba(28,132,198,0.4)',
            pointBackgroundColor: 'rgba(28,132,198,0.4)',
            pointHoverBackgroundColor: 'rgba(28,132,198,0.4)',
            pointHoverBorderColor: 'rgba(28,132,198,0.4)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.pageViews.previous)[1]
        },
        {
            label: 'Посетители',
            fill: false,
            backgroundColor: 'rgba(26,179,148,0.8)',
            borderColor: 'rgba(26,179,148,0.8)',
            pointBorderColor: 'rgba(26,179,148,0.8)',
            pointBackgroundColor: 'rgba(26,179,148,0.8)',
            pointHoverBackgroundColor: 'rgba(26,179,148,0.8)',
            pointHoverBorderColor: 'rgba(26,179,148,0.8)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.pageVisitors.current)[1]
        },
        {
            label: 'Посетители на прошлой неделе',
            fill: false,
            backgroundColor: 'rgba(26,179,148,0.4)',
            borderColor: 'rgba(26,179,148,0.4)',
            pointBorderColor: 'rgba(26,179,148,0.4)',
            pointBackgroundColor: 'rgba(26,179,148,0.4)',
            pointHoverBackgroundColor: 'rgba(26,179,148,0.4)',
            pointHoverBorderColor: 'rgba(26,179,148,0.4)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: getAnalytics(props.pageVisitors.previous)[1]
        }
    ];

    return (
        <LineChart
            datasets={datasetes}
            labels={getAnalytics(props.pageViews.current)[0]}
        />
    );
};

export default SourcesLineChart;
