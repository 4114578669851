// @flow
import API from '../axios';
import { FETCH_EMAILS_STATUSES } from "../constants/actionTypes";

type Action = {
    type: string,
    payload?: Object,
    error?: ?string
}

type Params = {
    start: number,
    end: number
}

/**
 * @param action
 * @returns {*}
 */
const dispatchEmailsStatuses = (action: Action) => {
    switch (action.type) {
        case 'request':
            return { type: FETCH_EMAILS_STATUSES.REQUEST };
        case 'success':
            return {
                type: FETCH_EMAILS_STATUSES.SUCCESS,
                payload: action.payload
            };
        case 'failure':
            return {
                type: FETCH_EMAILS_STATUSES.FAILURE,
                error: action.error
            };
        default:
            return {};
    }
};

/**
 *
 * @param params
 * @returns {Function}
 */
export function fetchEmailsStatuses(params: Params) {
    const url: string = "/emails/statuses/analytics?start=" + params.start + "&end=" + params.end;
    return async (dispatch: any): Promise<any> => {
        dispatch(dispatchEmailsStatuses({type: 'request'}));
        await API.get(url)
            .then(response => {
                dispatch(dispatchEmailsStatuses({type: 'success', payload: response.data}));
            })
            .catch(error => dispatch(dispatchEmailsStatuses({
                type: 'failure',
                error: error.response ? error.response.data.error_description : null
            })))
    };
}

/**
 *
 * @returns {Function}
 */
export function getEmailsStatuses() {
    return fetchEmailsStatuses({start: -7, end: 0});
}
