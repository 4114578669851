import React, { Component } from 'react';
import './LoginPage.scss';
import UniBaseLogo from  '../../assets/img/UniBase_vert.svg';
import {login} from '../../actions/AuthActions';
import {getHostName} from "../../_helpers/endpoints";
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            error:  null
        };
    }

    /**
     * @param e
     */
    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});

        let auth = this.props.login({
            username: this.refs.usernameInput.value,
            password: this.refs.passwordInput.value
        });

        auth.catch(e => this.setState({error: e}))
    }

    /**
     * @returns {*}
     */
    render() {
        const { submitted, error } = this.state;
        return (
            <div className="login-wrapper">
                <div className="middle-box">
                    <div className="logo-wrapper">
                        <img alt="UniBase Logo" src={UniBaseLogo} />
                    </div>

                    <div className="form-wrapper">
                        <h2>{getHostName() === 'backoffice' ? 'Панель управления' : 'Кабинет партнёра'}</h2>
                        <form name="login-form" onSubmit={this.handleSubmit.bind(this)}>
                            <div className="form-group">
                                <input ref="usernameInput" type="email" name="username" required="required" className="form-control" placeholder="Email" />
                            </div>
                            <div className="form-group">
                                <input ref="passwordInput" type="password" name="password" required="required" className="form-control" placeholder="Пароль"/>
                            </div>
                            <div className="form-group">
                                {submitted && error &&
                                <div>{error.message}</div>
                                }
                            </div>
                            <button className="btn">Войти</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapActionsToProps = dispatch => {
    return {
        login: bindActionCreators(login, dispatch)
    }
};

export default connect(null, mapActionsToProps)(LoginPage);
