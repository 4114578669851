// @flow
import React from 'react';
import './Ibox.scss';
import IboxTitle from './IboxTitle/IboxTitle';
import IboxContent from './IboxContent/IboxContent';
import PropTypes from 'prop-types';

type Props = {
    children?: any,
    data?: any,
    description?: ?string,
    name?: string,
    style?: ?Object
}

/**
 * @param props
 * @returns {*}
 */
const Ibox = (props: Props) => {
    let content = props.children ? props.children : (
        <IboxContent data={props.data} description={props.description} />
    );

    return (
        <div className="ibox" style={props.style}>
            {props.name && <IboxTitle name={props.name} />}
            {content}
        </div>
    );
};

Ibox.propTypes = {
    children: PropTypes.node,
    data: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    description: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object
};

export default Ibox;
