// @flow
import { FETCH_SOURCES_VIEWS } from '../constants/actionTypes';

type State = {
    views: {
        current: ?Object,
        previous: ?Object
    },
    visitors: {
        current: ?Object,
        previous: ?Object
    },
    loading: boolean,
    error: ?string
}

type Action = {
    type: string,
    payload: Object,
    error: ?string
}

export const initialState = {
    views: {
        current: null,
        previous: null
    },
    visitors: {
        current: null,
        previous: null
    },
    loading: false,
    error: null
};

const sourcesReducer = (state: State = initialState, action: Action): Object => {
    switch (action.type) {
        case FETCH_SOURCES_VIEWS.REQUEST:
            return {
                ...state,
                loading: true,
                views: {
                    current: null,
                    previous: null
                },
                visitors: {
                    current: null,
                    previous: null
                },
                error: null
            };
        case FETCH_SOURCES_VIEWS.SUCCESS:
            return {
                ...state,
                loading: false,
                views: {
                    current: action.payload.pageViews,
                    previous: action.payload.pageViewsPrevious
                },
                visitors: {
                    current: action.payload.pageVisitors,
                    previous: action.payload.pageVisitorsPrevious
                },
                error: null
            };
        case FETCH_SOURCES_VIEWS.FAILURE:
            return {
                ...state,
                loading: false,
                views: {
                    current: null,
                    previous: null
                },
                visitors: {
                    current: null,
                    previous: null
                },
                error: action.error
            };
        default:
            return state;
    }
};

export default sourcesReducer;
